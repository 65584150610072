import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-porque-appedidos',
  templateUrl: './porque-appedidos.component.html',
  styleUrls: ['./porque-appedidos.component.scss'],
})
export class PorqueAppedidosComponent implements OnInit {
  public customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    center: true,
    margin: 16,
    autoWidth: true,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      340: {
        items: 3,
      },
      600: {
        items: 2,
        center: false,
        autoWidth: false,
      },
      740: {
        items: 3,
        center: false,
        autoWidth: false,
      },
      900: {
        items: 4,
        center: false,
        autoWidth: false,
      },
      1600: {
        items: 4,
        center: false,
        autoWidth: true,
      },
    },
  };

  public ngOnInit(): void {
    setTimeout(() => {
      const tamanhoTela = window.innerWidth;
      if (tamanhoTela >= 800 && tamanhoTela < 1024) {
        document.getElementById('carouselPorqueAppedidos').style.paddingLeft =
          '39px';
      }
    }, 500);
  }
}
