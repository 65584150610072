import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sobre-nos',
  templateUrl: './sobre-nos.component.html',
  styleUrls: ['./sobre-nos.component.scss'],
})
export class SobreNosComponent implements OnInit {
  constructor() {}

  public ngOnInit() {
    window.scrollTo(0, 0);
  }
}
