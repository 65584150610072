import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { HeaderHomeComponent } from './components/header-home/header-home.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { DivulgaAppComponent } from './components/divulga-app/divulga-app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselFluxoComponent } from './components/carousel-fluxo/carousel-fluxo.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { PorqueAppedidosComponent } from './components/porque-appedidos/porque-appedidos.component';
import { BaixarAppComponent } from './components/baixar-app/baixar-app.component';
import { ParceirosComponent } from './components/parceiros/parceiros.component';
import { CidadesAtuacaoComponent } from './components/cidades-atuacao/cidades-atuacao.component';
import { BotaoPilulaComponent } from './components/botao-pilula/botao-pilula.component';
import { RedesSociaisComponent } from './components/redes-sociais/redes-sociais.component';
import { SobreNosComponent } from './pages/sobre-nos/sobre-nos.component';
import { PoliticaPrivacidadeComponent } from './pages/politica-privacidade/politica-privacidade.component';
import { TermosCondicoesComponent } from './pages/termos-condicoes/termos-condicoes.component';
import { MenuComponent } from './components/menu/menu.component';
import { HeaderPageComponent } from './components/header-page/header-page.component';
import { DesktopCarouselFluxoComponent } from './components/desktop-carousel-fluxo/desktop-carousel-fluxo.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderHomeComponent,
    HeaderComponent,
    FooterComponent,
    DivulgaAppComponent,
    CarouselFluxoComponent,
    PorqueAppedidosComponent,
    BaixarAppComponent,
    ParceirosComponent,
    CidadesAtuacaoComponent,
    BotaoPilulaComponent,
    RedesSociaisComponent,
    SobreNosComponent,
    PoliticaPrivacidadeComponent,
    TermosCondicoesComponent,
    MenuComponent,
    HeaderPageComponent,
    DesktopCarouselFluxoComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
