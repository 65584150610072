import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  public openMenu = false;

  public open(): void {
    this.openMenu = !this.openMenu;

    if (this.openMenu) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    const element = document.getElementById('header');
    const button = document.getElementById('button-appedidos');

    if (window.pageYOffset > 50) {
      element.classList.add('header--fixed');
      button.classList.add('button-appedidos--small');
    } else {
      element.classList.remove('header--fixed');
      button.classList.remove('button-appedidos--small');
    }
  }
}
