import { Component, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-divulga-app',
  templateUrl: './divulga-app.component.html',
  styleUrls: ['./divulga-app.component.scss'],
})
export class DivulgaAppComponent implements AfterViewInit {
  constructor() {}

  public ngAfterViewInit(): void {
    const headerHeight = document.getElementById('header-home').offsetHeight;
  }
}
