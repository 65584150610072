import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-home',
  templateUrl: './header-home.component.html',
  styleUrls: ['./header-home.component.scss'],
})
export class HeaderHomeComponent implements OnInit {
  public ngOnInit(): void {
    parent.window.scrollTo(0, 0);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    const element = document.getElementById('header-home');
    const button = document.getElementById('button-appedidos');

    if (window.pageYOffset > 50) {
      element.classList.add('header-home--fixed');
      button.classList.add('button-appedidos--small');
    } else {
      element.classList.remove('header-home--fixed');
      button.classList.remove('button-appedidos--small');
    }
  }
}
