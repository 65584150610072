import { Component, HostListener, ViewChild, ElementRef } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-desktop-carousel-fluxo',
  templateUrl: './desktop-carousel-fluxo.component.html',
  styleUrls: ['./desktop-carousel-fluxo.component.scss'],
})
export class DesktopCarouselFluxoComponent {
  @ViewChild('scroll', { static: false })
  scroll: ElementRef;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    $('.desktop-carousel-fluxo__carousel').scroll((e) => {
      const scroll = e.target.scrollTop;
      const element = $('.desktop-carousel-fluxo__content');
      const scrollItem = $('.desktop-carousel-fluxo__carousel__list__item');
      const classesPadrao =
        'desktop-carousel-fluxo__content d-flex align-items-center';

      const scrollItemCssClass =
        'desktop-carousel-fluxo__carousel__list__item--ativo';

      // console.log(scroll);

      if (scroll > 150 && scroll < 440) {
        element.removeClass();
        element.addClass(
          `${classesPadrao} desktop-carousel-fluxo__content--selecione-restaurante`,
        );
        scrollItem.removeClass(scrollItemCssClass);
        $('#selecione-restaurante').addClass(scrollItemCssClass);
      } else if (scroll > 440 && scroll < 680) {
        element.removeClass();
        element.addClass(
          `${classesPadrao} desktop-carousel-fluxo__content--faca-pedido`,
        );
        scrollItem.removeClass(scrollItemCssClass);
        $('#faca-pedido').addClass(scrollItemCssClass);
      } else if (scroll > 680 && scroll < 840) {
        element.removeClass();
        element.addClass(
          `${classesPadrao} desktop-carousel-fluxo__content--escolha-pagamento`,
        );
        scrollItem.removeClass(scrollItemCssClass);
        $('#escolha-pagamento').addClass(scrollItemCssClass);
      } else if (scroll >= 840) {
        element.removeClass();
        element.addClass(
          `${classesPadrao} desktop-carousel-fluxo__content--pronto`,
        );
        scrollItem.removeClass(scrollItemCssClass);
        $('#pronto').addClass(scrollItemCssClass);
      } else {
        element.removeClass();
        element.addClass(
          `${classesPadrao} desktop-carousel-fluxo__content--escolha-cidade`,
        );
        scrollItem.removeClass(scrollItemCssClass);
        $('#escolha-cidade').addClass(scrollItemCssClass);
      }
    });
  }
}
