import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-carousel-fluxo',
  templateUrl: './carousel-fluxo.component.html',
  styleUrls: ['./carousel-fluxo.component.scss'],
})
export class CarouselFluxoComponent {
  public customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    center: true,
    margin: 16,
    autoWidth: true,
    nav: false,
    responsive: {
      0: {
        items: 1,
        autoWidth: false,
      },
      340: {
        items: 2,
      },
      400: {
        items: 1,
      },
      740: {
        items: 3,
        center: false,
      },
    },
  };

  constructor() {}
}
