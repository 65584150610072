import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-parceiros',
  templateUrl: './parceiros.component.html',
  styleUrls: ['./parceiros.component.scss'],
})
export class ParceirosComponent {
  public customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    center: true,
    margin: 16,
    autoWidth: true,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      340: {
        items: 1,
      },
      500: {
        items: 4,
        center: false,
        autoWidth: false,
      },
      800: {
        items: 4,
        center: false,
        autoWidth: false,
      },
      1500: {
        items: 5,
        center: false,
        autoWidth: true,
      },
    },
  };
}
