import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-termos-condicoes',
  templateUrl: './termos-condicoes.component.html',
  styleUrls: ['./termos-condicoes.component.scss'],
})
export class TermosCondicoesComponent implements OnInit {
  public ngOnInit() {
    window.scrollTo(0, 0);
  }
}
