import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-politica-privacidade',
  templateUrl: './politica-privacidade.component.html',
  styleUrls: ['./politica-privacidade.component.scss'],
})
export class PoliticaPrivacidadeComponent implements OnInit {
  public ngOnInit() {
    window.scrollTo(0, 0);
  }
}
