import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-botao-pilula',
  templateUrl: './botao-pilula.component.html',
  styleUrls: ['./botao-pilula.component.scss'],
})
export class BotaoPilulaComponent {
  @Input() public texto: string;
  constructor() {}
}
